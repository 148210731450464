import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Button from "../../../../components/elements/Button"
import Card from "../../../../components/elements/Card"
import TextInput from "../../../../components/elements/Inputs/TextInput"
import AccountNavigation from "../../Navigation"
import axios from "axios";
import { getUserToken } from "../../../../services/auth"
import LoadingSpinner from "../../../../components/elements/LoadingSpinner"
import { API_BASE } from "../../../../spectory-config"
import { renderDate } from "../../../../functions/date"
import { setNotification } from "../../../../store/actions"
import { useDispatch } from "react-redux"

const Address = (props) => {

    const { register, handleSubmit } = useForm();

    const [address, setAddress] = useState([]);
    const [addresses, setAddresses] = useState([]);
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        var config = {
            method: 'post',
            url: `${API_BASE}/customer/addresses/get`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                token: getUserToken()
            }
        };


        axios(config)
            .then(function (response) {
                console.log("response", response.data.data);

                setAddresses(response.data.data);
                setAddress(response.data.data[props.type])

                setLoading(false);
            })
            .catch(function (error) {
                console.log("response err", error)
                setLoading(false);
            });
    }, []);


    const send = (data) => {

        setLoading(true)
        let _addresses = addresses;

        if (props.type === 'billing') {
            _addresses.billing = data;
        } else {
            _addresses.shipping = data;
        }

        var config = {
            method: 'post',
            url: `${API_BASE}/customer/addresses/set`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                token: getUserToken(),
                billing: _addresses.billing,
                shipping: _addresses.shipping,
            }
        };


        axios(config)
            .then(function (response) {
                console.log("response", response);

                if(response.data.c === 200){
                    dispatch(setNotification({
                        type: "success",
                        title: 'Änderungen wurden gespeichert',
                        show: true,
                    }));
                }else{
                    dispatch(setNotification({
                        type: "error",
                        title: 'Es ist ein Fehler aufgetreten',
                        show: true,
                    }));
                }

                setLoading(false);
            })
            .catch(function (error) {
                console.log("response err", error)
                setLoading(false);
            });
    }


    return (
        <div className="container" style={{ paddingTop: 100, paddingBottom: 100 }}>
            <div className="col4">
                <AccountNavigation current={'konto/addresses'} />
            </div><div className="col8">
                <div>
                    <h4>{props.type === 'shipping' ? 'Lieferadresse' : 'Rechnungsadresse'} bearbeiten</h4>

                    <form onSubmit={handleSubmit(send)}>
                        <div className="container fluid" style={{ padding: 0 }}>
                            <div className="col6">
                                <div>
                                    <TextInput
                                        required
                                        initValue={address['first_name']}
                                        type="text"
                                        label="Vorname"
                                        {...register('first_name')}
                                    />
                                </div>
                            </div>
                            <div className="col6">
                                <div>
                                    <TextInput
                                        required
                                        initValue={address['last_name']}
                                        type="text"
                                        label="Nachname"
                                        {...register('last_name')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="container fluid" style={{ padding: 0 }}>
                            <div className="col12">
                                <div>
                                    <TextInput
                                        required
                                        initValue={address['address_1']}
                                        type="text"
                                        label="Straße"
                                        {...register('address_1')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="container fluid" style={{ padding: 0 }}>
                            <div className="col12">
                                <div>
                                    <TextInput
                                        initValue={address['address_2']}
                                        type="text"
                                        label="Adresszusatz (optional)"
                                        {...register('address_2')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="container fluid" style={{ padding: 0 }}>
                            <div className="col6">
                                <div>
                                    <TextInput
                                        required
                                        initValue={address['postcode']}
                                        type="text"
                                        label="PLZ"
                                        {...register('postcode')}
                                    />
                                </div>
                            </div>
                            <div className="col6">
                                <div>
                                    <TextInput
                                        required
                                        initValue={address['city']}
                                        type="text"
                                        label="Ort / Stadt"
                                        {...register('city')}
                                    />
                                </div>
                            </div>
                        </div>
                        {
                            props.type === 'billing' &&
                            <>
                                <div className="container fluid" style={{ padding: 0 }}>
                                    <div className="col12">
                                        <div>
                                            <TextInput
                                                required
                                                initValue={address['email']}
                                                type="email"
                                                label="E-Mail"
                                                {...register('email')}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="container fluid" style={{ padding: 0 }}>
                                    <div className="col12">
                                        <div>
                                            <TextInput
                                                initValue={address['phone']}
                                                type="phone"
                                                label="Telefonnummer"
                                                {...register('phone')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        <Button
                            title="Adresse speichern"
                            type="submit"
                        />
                    </form>

                </div>
            </div>
            <LoadingSpinner type="page" active={loading} />
        </div>
    )
}

export default Address
