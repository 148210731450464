import React, { useEffect } from "react"
import * as styles from './style.module.scss';

const Card = ({ label, value }) => {

    return (
        <div className={styles.container}>
            <div className={styles.label}>
                {label}
            </div>
            <div className={styles.value}>
                {value}
            </div>
        </div>
    )
}

export default Card
