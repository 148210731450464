import { Link } from "@reach/router";
import React, { useEffect } from "react"
import * as styles from './style.module.scss';

const AccountNavigation = ({ current }) => {

    const pages = {
        'konto': "Ihr Konto",
        'konto/orders': "Bestellungen",
        'konto/addresses': "Adressen",
        'konto/payments': "Zahlungsmethoden",
        'konto/settings': "Kontoeinstellungen"
    };


    return (
        <ul className={styles.container}>
            {
                Object.keys(pages).map((path) => {
                    console.log("path", path, current)

                    return <li className={current == path ? styles.current : null}>
                        <Link to={`/${path}`}>{pages[path]}</Link>
                    </li>
                })
            }
            <li>
                <Link to="/login" onClick={() => {
                    if (window !== undefined) {
                        localStorage.removeItem('token');
                        localStorage.removeItem('userdata');
                        sessionStorage.removeItem('token');
                        sessionStorage.removeItem('userdata');
                    }
                }}>Abmelden</Link>
            </li>
        </ul>
    )
}

export default AccountNavigation
